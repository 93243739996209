@use '../sass/abstracts/mixins' as m;

.main {
  display: flex;
  position: relative;
  grid-column: 2 / 4;
  flex-direction: column;
  gap: 1em;
  /* margin-top: 7rem; */

  justify-content: center;
  align-items: center;

  @include m.mediaUpToDesktop {
    grid-column: 1 / -1;
  }
}
