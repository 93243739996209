@use '../sass/abstracts/variables' as v;
@use '../sass/abstracts/mixins' as m;

.backdrop {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 30;
  background-color: v.$modal-backdrop;
}

.modal {
  position: fixed;
  top: 5%;
  left: 0;
  width: 100%;
  background-color: v.$modal-color;
  padding: 3rem 2rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  text-align: center;

  & button {
    margin-top: 1rem;
  }

  & h3 {
    padding-bottom: 1rem;
  }

  & p {
    margin: 1rem 0 1rem;
  }
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close {
  display: block;
  position: fixed;
  top: 4%;
  right: 2%;
  cursor: pointer;
  width: fit-content;
}
