@use '../abstracts/variables' as v;
@use '../abstracts/mixins' as m;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  cursor: default;
}

body {
  color: #343a40;
  font-size: 1.6rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f3f5;
}

li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: grid;
  grid-template-columns: 210px auto 210px;
  height: 100vh;
  @include m.mediaPhone {
    grid-template-columns: auto;
    grid-template-rows: 50px 1fr;
  }
}

.btn,
button {
  display: flex;
  font: inherit;
  background-color: v.$accent-color;
  border: 1px solid v.$accent-color;
  color: white;
  letter-spacing: 0.1rem;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
}

.btn {
  text-decoration: none;
}

.btn:hover,
.btn:active,
button:hover,
button:active {
  background-color: v.$light-accent-color;
  border: 1px solid v.$accent-color;
  color: v.$accent-color;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.219);
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  // border-color: #ccc;
  cursor: not-allowed;
}

.btn__website {
  margin: 1rem auto 1rem;
}

.centered {
  text-align: center;
  gap: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-flex {
  display: flex;
  flex-direction: column;
}

.container {
  align-self: center;
  height: 100%;
  @include m.mediaDesktop {
    max-width: 900px;
  }
}

.start-container {
  height: 100vh;
  width: 100%;
  justify-content: center;
  gap: 1em;
  display: flex;
  grid-column: 2 / 4;
  @include m.mediaPhone {
    flex-direction: column;
    align-items: center;
  }
}

.title {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  height: 70px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.151);
}

.sub-item,
.user-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  background-color: rgb(255, 255, 255);
  font-size: 1.4rem;
  border: 1px solid #777;
  gap: 1em;
  /* width: 1100px; */
  height: 70px;
}

.title-bar {
  /* width: 1100px; */
  height: 3em;
  border: 1px solid #636363;
  display: grid;
  background-color: rgb(255, 255, 255);
  align-items: center;
  /* justify-items: center; */
  grid-template-columns: repeat(4, 1fr);
}

.title-bar p,
.sub-item p,
.user-item p {
  margin-left: 1em;
}

.sub-item:nth-child(odd) {
  background-color: rgb(238, 238, 238);
}

.sub-item:hover,
.sub-item:active {
  background-color: rgb(170, 170, 170);
  cursor: pointer;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.205);
}

.modal__content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3em;
  height: 4rem;
}

.modal {
  position: relative;
  top: 0;
  // left: 50%;
  // width: 20%;
  background-color: rgba(2, 129, 55, 0.575);
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  color: rgb(255, 255, 255);
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.user-view {
  background-color: #fff;
  width: 1100px;
  min-height: 100vh;
  border-radius: 1px;
  overflow: hidden;
  -webkit-box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.07);
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.07);
  display: flex;
  gap: 1em;
  justify-content: center;
}
.user-view__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 7rem 0;
}

.side-bar__container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.heading {
  display: flex;

  gap: 1em;
  align-self: flex-start;
}

.side-bar__buttons {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.side-bar__messages {
  margin-top: 1em;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  align-items: center;
}

.main {
  padding: 8rem 6rem;
}

.login-form {
  margin: 0 auto;
  max-width: 55rem;
  background-color: #fff;
  -webkit-box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  padding: 5rem 7rem;
  border-radius: 5px;
}

.form__input {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  padding: 0.5rem 0.75rem;
  width: 100%;
  background-color: #fff;
  border: 1px solid #777;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* Pseudo element (a visible thing that isn't really in the DOM).
        Also needs -ms- */
}
.form__input:focus {
  outline: none;
  border: 2px solid #6d55c5;
}
.form__input:focus:invalid {
  border: 3px solid #ff7730;
}
.form__input::-webkit-input-placeholder {
  color: #bbb;
}

.invalid {
  border: 2px solid #ff0000;
}

.form__group:not(:last-child) {
  margin-bottom: 2.5rem;
}

.form__label {
  display: block;
  color: rgb(65, 65, 65);
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form__photo-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
}

.form__user-photo {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
  margin-right: 2rem;
}

.form__upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form__upload:focus + label {
  outline: 3px solid #55c57a;
  outline-offset: 3px;
}

.form__upload + label {
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.form__upload + label:hover {
  background-color: #55c57a;
  color: #fff;
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.form__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-bottom: 1em;
}

.wide {
  grid-column: 1 / 3;
}

.tall {
  min-height: 100vh;
}

.ck-editor__editable_inline {
  min-height: 400px;
}

#form-file-upload {
  height: 200px;
  width: 400px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.title {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
}

.hidden {
  display: none;
}
.logo-container {
  @include m.flexCenter();
  text-align: center;
  max-height: 10rem;
}

.logo {
  height: 50px;
}

.logo-small {
  width: 30px;
  margin: 0.5em;
}

.logo-link {
  text-decoration: none;
  color: #383838;
}

.preview__total {
  margin-left: 0.5em;
  color: #f1f3f5;
}

.preview-post__heading,
.website-list__heading {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5em;
  gap: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: v.$primary-color;
  color: v.$text-color-light;
  & h3 {
    letter-spacing: 0.15rem;
  }

  @include m.mediaPhone {
    border-radius: 0;
  }
}

.preview-post__title {
  color: v.$accent-color;
  line-height: 1.9;
}

.preview-post__title a,
.preview-post__edit {
  text-decoration: none;

  color: v.$accent-color;
}

.preview-post__body {
  @include m.mediaPhone {
    display: none;
  }
}

.preview-post__draft {
  color: rgb(105, 105, 105);
  font-weight: 400;
  margin-left: 1em;
}

.preview-post__edit {
  font-size: 1.3rem;
  border-radius: 10px;
  padding: 0 0.5em 0 0.5em;
  border: 1px solid #1864ab;
}

.preview-post__row {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  gap: 1em;
  border-radius: 5px;
  padding: 1em;
  border: 1px solid #d3d0d0;
  margin: 0.5em;
  background-color: #f8f9fa;
  font-size: 1.4rem;
}

.preview-email {
  @include m.flexCenter();
  gap: 1em;
  border-radius: 5px;
  padding: 1em;
  border: 1px solid #d3d0d0;
  margin: 0.5em;
  background-color: #f8f9fa;
  font-size: 1.4rem;

  @include m.mediaPhone {
    flex-direction: column;
    align-items: flex-start;
  }
}

.preview-sub__card {
  cursor: pointer;
  border-radius: 5px;
  max-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.preview-email__card {
  border-radius: 5px;
  max-width: 900px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background-color: white;

  & a {
    text-decoration: none;
  }
}

.preview-sub__row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1em;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid #d3d0d0;
  margin: 0.3em;
  background-color: #f8f9fa;
  font-size: 1.3rem;
}

.welcome {
  @include m.flexCenter(column);
  height: 50vh;

  gap: 1rem;
  margin: 2rem 0 3rem 0;

  & img {
    max-height: 10rem;
  }

  & h1 {
    font-family: 'Oswald', sans-serif;
    margin-top: -2rem;
    font-size: 4.5rem;
  }
}

.website-list__row {
  display: grid;
  cursor: pointer;
  grid-template-columns: 70px 1fr 1fr 1fr;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid #d3d0d0;
  margin: 0.5rem;
  background-color: #f8f9fa;
  font-size: 1.6rem;

  & p:nth-child(2) {
    text-transform: uppercase;
    font-weight: 600;
    color: v.$accent-color;
  }

  @include m.mediaPhone {
    @include m.flexCenter(column);
    margin: 0;
  }
}

.website-list__row:hover,
.website-list__row:focus {
  background-color: v.$light-accent-color;
  color: v.$accent-color;
  border-color: v.$accent-color;
}

.heading {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  margin-top: 1em;
}

.login__container {
  grid-column: 1 / -1;
  display: grid;
  width: 100%;
  align-items: center;
  background-color: v.$light-primary-color;
  & img {
    height: 10rem;
  }

  @include m.mediaTablet {
    grid-template-columns: 2fr 1fr;
  }

  @include m.mediaDesktop {
    grid-template-columns: 4fr 5fr;
  }
}

.logo {
  font-family: 'Oswald', sans-serif;
  margin-bottom: 3rem;

  @include m.mediaPhone {
    margin-bottom: 0rem;
  }
}

.login-form__container {
  @include m.flexCenter(column);
  width: 80%;
  margin-inline: auto;

  @include m.mediaPhone {
    width: 90%;
  }
}

.login__hero {
  @include m.flexCenter(column);
  height: 95%;

  border-radius: 10px 0 0 10px;
  background: v.$gradient-color;

  @include m.mediaPhone {
    display: none;
  }
}

.container-email {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  @include m.mediaPhone {
    flex-direction: column;
    align-items: center;
  }
}
