$primary-color: #495057;
$light-primary-color: #dbdbdb;
$accent-color: #8626fa;
$gradient-color: linear-gradient(
  295deg,
  rgba(224, 59, 251, 1) 0%,
  rgba(134, 38, 250, 1) 100%
);

$light-accent-color: #d0bfff;
$mid-color: #8526faa9;
$light-background-color: #f3f3f3;

$alert-color: rgb(228, 108, 108);

$text-color-light: #f8f9fa;
$text-color-dark: #343a40;

$modal-backdrop: rgba(0, 0, 0, 0.25);
$modal-color: rgb(241, 241, 241);
