@use '../../sass/abstracts/mixins' as m;
@use '../../sass/abstracts/variables' as v;

.container {
  padding: 2rem;

  border-radius: 6px;
  width: 80%;

  @include m.mediaPhone {
    width: 100%;
  }
  // background-color: v.$light-background-color;
}

.btnContainer {
  @include m.flexCenter();
  margin-top: 1rem;
}

.btnForgot {
  background-color: v.$light-primary-color;
  color: v.$accent-color;
  font-size: 1.3rem;
}

.input {
  display: block;
  font-size: 1.5rem;
  padding: 1rem;
  width: 100%;
  background-color: #fff;
  border: 2px solid v.$light-accent-color;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;

  &:focus {
    outline: none;
    border: 2px solid v.$accent-color;
  }
  &:focus:invalid {
    border: 2px solid v.$alert-color;
  }
}

.invalid {
  border: 2px solid v.$alert-color;
}

.form {
  @include m.flexCenter(column);
  width: 100%;
  gap: 3rem;
}
