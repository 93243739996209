@use '../../../sass/abstracts/variables' as v;
@use '../../../sass/abstracts/mixins' as m;

.nav {
  align-self: start;
  @include m.mediaUpToDesktop {
    display: none;
  }
}

.nav ul {
  display: flex;
  gap: 1em;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: v.$accent-color;
  color: #edf2ff;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 8px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn:hover {
  background-color: v.$light-accent-color;
  color: v.$accent-color;
  border: 1px solid v.$accent-color;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.253);
}

.username {
  text-align: center;
}

.welcome {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
}

.nav ul {
  display: grid;
  grid-template-rows: repeat(5 1fr);
}

.logo {
  border-bottom: 1px solid #e0e0e0;
  @include m.flexCenter();
  text-align: center;
  align-self: stretch;
  justify-self: stretch;
}
