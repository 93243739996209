@use '../sass/abstracts/variables' as v;
@use '../sass/abstracts/mixins' as m;

.nav {
  align-self: start;
}

.nav ul {
  display: flex;
  gap: 1em;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: v.$accent-color;
  color: #edf2ff;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 8px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.btn:hover {
  background-color: v.$light-accent-color;
  color: v.$accent-color;
  border: 1px solid v.$accent-color;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.253);
}

.username {
  text-align: center;
}

.header {
  @include m.mediaUpToDesktop {
    grid-column: 1/ 5;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    z-index: 100;
  }

  @include m.mediaDesktop {
    display: grid;
    border-right: 1px solid rgba(0, 0, 0, 0.151);
    grid-column: 1 / 2;
    position: fixed;
    height: 100vh;
    width: 210px;
    grid-template-columns: 1fr;
    grid-template-rows: 7rem;
    justify-items: center;
    align-items: center;
  }
}
.welcome {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
}

.nav ul {
  display: grid;
  grid-template-rows: repeat(5 1fr);
}

.logo {
  & a {
    text-decoration: none;
    @include m.flexCenter();
    margin-left: 2rem;

    & img {
      width: 3rem;
    }

    & h2 {
      color: v.$text-color-dark;

      // font-size: 1rem;
      margin-left: 0.5rem;
      font-family: 'Oswald', sans-serif;
    }

    @include m.mediaDesktop {
      border-bottom: 1px solid #e0e0e0;
      text-align: center;
      align-self: stretch;
      justify-self: stretch;
      & img {
        width: 5rem;
      }
    }
  }
}
