@import '../../../sass/abstracts/variables';

.nav {
  align-self: start;
}

.nav a,
.nav div {
  text-decoration: none;
  display: flex;
  gap: 0.3em;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  color: #383838;
}

.nav {
  font-size: 1.5rem;
  width: 170px;
  line-height: 1.7;
}

.nav a:hover,
.nav a:active,
.nav a.active,
.nav div:hover,
.nav div:active {
  color: $accent-color;
  background-color: $light-primary-color;
  border-radius: 4px;
}
