@use '../sass/abstracts/mixins' as m;

.card {
  /* padding: 1rem; */
  /* margin: 1rem; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding-bottom: 0.5em;

  @include m.mediaDesktop {
    width: 700px;

    & ul {
      width: 700px;
    }
    & li {
      width: 680px;
    }
  }
}
